$('.btn-copy').on('click', function() {
	var classVal = $(this).attr('class');
	var classVals = classVal.split(' ');
	var btnClass;
	var btnClassName;
	var copyText = {
		'btn-copy1':  'trunkご紹介テキスト1',
		'btn-copy2':  'trunkご紹介テキスト2',
		'btn-copy3':  'trunkご紹介テキスト3',
	}
	
	for(var i=0; i<classVals.length; i++) {
		if(classVals[i] != 'btn-copy') {
			btnClassName = classVals[i];
			btnClass = '.' + btnClassName;
		}
	}
	
	var clipboard = new Clipboard(btnClass);
	var clipboardText = copyText[btnClassName] + 'をコピーしました';
	var popUpHtml = '<div id="pop-up"><span class="pop-up-text">' + clipboardText + '</span></div>'
		
	clipboard.on('success', function(e) {
		if(!($('#pop-up').length)) {	
			$('body').append(popUpHtml);
			$('.pop-up-text').fadeIn('fast', function() {
				setTimeout(function() {
					$('#pop-up').fadeOut('fast').queue(function() {
						this.remove();
					});
				}, 1000);
			});
		} 
		
		e.clearSelection();
	});
});