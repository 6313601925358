var clickFlag = true;
$('.menu-trigger').on('click', function() {
  if(clickFlag) {
    clickFlag = false;
    var hasActive = $('.menu-trigger').hasClass('active');

    if (hasActive) {
      $(this).toggleClass('active');
      $('.nav-sp').fadeOut(function() {
        clickFlag = true;
      });
    }
    else {
      $(this).toggleClass('active');
      $('.nav-sp').fadeIn(function() {
        clickFlag = true;
      });
    }
    return false;
  }
  else {
    return false;
  }
});

$(window).on('resize', function() {
  var windowWidth = $(window).width();
  var spWidth = 766;

  if(windowWidth > spWidth) {
    var hasActive = $('.menu-trigger').hasClass('active');
    if(hasActive) {
      $('.nav-sp').hide();
      $('.menu-trigger').removeClass('active');
    }
  }

});