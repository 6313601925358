$(function() {
    var isBusy = false;

    $('#contact').find('#submit').click(function() {
        if(isBusy) {
            return false;
        }

        isBusy = true;

        sendContact();

        return false;
    });

    function sendContact() {
        var type = $('#contact-type').val().trim();
        var name = $('#contact-name').val().trim();
        var nameKana = $('#contact-furigana').val().trim();
        var companyName = $('#contact-company-name').val().trim();
        var email = $('#contact-mail').val().trim();
        var message = $('#contact-message').val().trim();

        $('.inquiry__form .error, .inquiry__form .transmission-error').css('display', 'none');

        // required validation
        var hasError = false;

        if(type == "") {
            $('#contact-type').parent().siblings().css('display', 'block');
            hasError = true;
        }

        if(name == "") {
            $('#contact-name').siblings().css('display', 'block');
            hasError = true;
        }

        if(nameKana == "") {
            $('#contact-furigana').siblings().css('display', 'block');
            hasError = true;
        }

        if(email == "") {
            $('#contact-mail').siblings().css('display', 'block');
            hasError = true;
        }

        if(message == "") {
            $('#contact-message').siblings().css('display', 'block');
            hasError = true;
        }

        if(hasError) {
            isBusy = false;

            return false;
        }

        return execContactApi({
            type: type,
            name: name,
            nameKana: nameKana,
            company: companyName,
            email: email,
            message: message
        });
    }

    function execContactApi(data) {
        $.ajax({
            url: 'https://ko4q343h07.execute-api.ap-northeast-1.amazonaws.com/v1/contact',
            type: "POST",
            data: JSON.stringify(data)
        }).then(function(data){
            if(data.success) {
                location.href="finish.html";
            } else {
                $('.transmission-error').css('display', 'block');
            }
        }, function() {
            $('.transmission-error').css('display', 'block')
        }).always(function() {
            isBusy = false;
        });
    }
});