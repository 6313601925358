function check() {
  $('#check').change(function() {
    if($(this).prop('checked')) {
      $('#submit').prop('disabled', false).addClass('abled');
    }else {
      $('#submit').prop('disabled', true).removeClass('abled');
    }
  });
}

check();

window.onpageshow = function() {
  $('#check').prop('checked', false);
}