var topBtn = $('#page-to-top');
topBtn.hide();

$(window).scroll(function() {
  var scrollHeight = $(document).height();
  var scrollPosition = $(window).height() + $(window).scrollTop();
  if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
    topBtn.fadeOut();
  }
  else if($(this).scrollTop() > 80 && $(this).scrollTop() < $(document).height()) {
    topBtn.fadeIn();
  }
  else {
    topBtn.fadeOut();
  }
});

topBtn.click(function() {
  $('body, html').animate({
    scrollTop: 0,
  }, 500);
});